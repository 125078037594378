<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link to="/info">个人中心</router-link>><a href="javascript:;">发票管理</a>
        </div>
      </div>
      <div class="personal per_invoice pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info">基本信息</router-link>
          <router-link to="/certificate">证书抬头</router-link>
          <router-link to="/maintain_list">开票信息</router-link>
          <router-link to="/address_list">收件地址</router-link>
          <router-link to="/invoices_status" class="active">发票管理</router-link>
          <router-link to="/contract_management">合同管理</router-link>
          <router-link to="/change_password">修改密码</router-link>
          <router-link to="/change_phone">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
        </div>
        <div class="right_box flex-grow-1">
          <div class="fs18 fwb pb30">开票信息</div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">订单编号：</div>
            <div class="fs16">{{ order_no }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">发票形式：</div>
            <div class="fs16">{{detail.materialType=='ELECTRIC'?'电子':'纸质'}}发票</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">发票类型：</div>
            <div class="fs16">{{detail.invoiceType=='COMMON'?'普通':'专用'}}发票</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">发票抬头：</div>
            <div class="fs16">{{ invoice.title }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">纳税识别号：</div>
            <div class="fs16">{{ invoice.texNo }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">开户行：</div>
            <div class="fs16">{{ invoice.bankName }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">银行账号：</div>
            <div class="fs16">{{ invoice.account }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">详细地址：</div>
            <div class="fs16">{{ invoice.address }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">联系电话：</div>
            <div class="fs16">{{ invoice.tel }}</div>
          </div>
          <div v-if="address !=null">
            <div class="fs18 fwb pb20 pt40 mt10 border">收件信息</div>
          <div class="name_box">
            <div class="flex-box flex-between pb20">
              <div class="fs16 col10 flex-box">
                <span class="invoice_title col9">收件人：</span><span>{{ address.receiverName }}</span>
              </div>
            </div>
            <div class="fs16 col10 pb20 flex-box">
              <span class="invoice_title col9">详细地址：</span><span>{{ address.region }}{{ address.addressDetail }}</span>
            </div>
            <div class="flex-box flex-between">
              <div class="fs16 col10 flex-box">
                <span class="invoice_title col9">联系电话：</span><span>{{ address.receiverTel_text }}</span>
              </div>
            </div>
          </div>
          </div>
          <div class="save">开票中</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import cookie from "vue-cookies";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
export default {
  data() {
    return {
      order_id: 0,
      order_no: '',
      address:null,
      invoice: { },
      address_list: [],
      detail:{}
    };
  },

  created() {
    let that = this
    that.order_id = that.$route.query.id
    that.order_no = that.$route.query.no
    var order_id = unescape(that.$route.query.id)
    that.order_id = order_id.replace('环保桥', '')
    var no = unescape(that.$route.query.no)
    that.order_no = no.replace('环保桥', '')
    that.get_invoice(that.order_id)
  },
  methods: {

    //获取发票
    get_invoice(id) {
      let that = this;
      let s_data = {
		  		tradeId: id,
				  pkg_invoice_father:'com.dazz.business.model.invoice.Invoice',
		  		pkg_address_father: 'com.dazz.business.model.address.Address'
		  	}
        that.$api.tradeInvoiceDetail(s_data).then((res) => {
        let data = res.root[0]
				// console.log(data)
		  		if (data.invoice) {
		  			that.invoice = data.invoice[0]
		  		}
				if (data.address) {
					let address = data.address[0]
					var tel = address.receiverTel.substring(0, 3) + "****" + address.receiverTel.substr(address
						.receiverTel.length - 4);
            address.receiverTel_text = tel
            that.address = address
				}
		  		that.detail = data
      }).catch((err) => {
        console.log(err);
      });
    },
     //删除
     cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id}).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },

  }

}

</script>

